import React, { useEffect, useState } from 'react'
import { gsap } from 'gsap';
import Overlay from "../assets/videos/subpage_banner.mp4"
import OverlayMobile from "../assets/images/video-to-jpg/subpage_banner.png"

const SubpageBanner = ({ title, subtitle }) => {
    useEffect(() => {
        // GSAP animasyonları
        gsap.fromTo(
            ".subpage_leftside",
            { x: -100, opacity: 0 },
            { duration: .7, x: 0, opacity: 1, ease: "power1.inOut", delay: .3 }
        );
        gsap.fromTo(
            ".subpage_banner_image",
            { x: 100, opacity: 0 },
            { duration: .7, x: 0, opacity: 1, ease: "power1.inOut", delay: .3 }
        );
    }, []);

    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 768px)").matches
    )

    useEffect(() => {
        window
            .matchMedia("(min-width: 768px)")
            .addEventListener('change', e => setMatches(e.matches));
    }, []);

    return (
        <>
            <div className="subpage_banner_content">
                <div className="container">
                    <div className="row align-items-end position-relative">
                        <div className="col-lg-12 subpage_leftside">
                            <h1>{title}</h1>
                            <h3 className='subpage_subtitle'>
                                {subtitle}
                            </h3>
                        </div>
                    </div>
                </div>
                {
                    matches ?
                        <video className="banner_video" autoPlay loop muted playsInline preload="auto" >
                            <source src={Overlay} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        :
                        <img src={OverlayMobile} alt="banner" style={{ position: "absolute", width: "100%", height: "101%", top: "0", zIndex: "-1" }} />
                }

            </div>
        </>
    )
}

export default SubpageBanner